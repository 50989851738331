@font-face {
  font-family: 'CompassPro';
  font-style: normal;
  font-weight: 300;
  src: url(./CompassPro.woff) format('woff'),
       url(./CompassPro.otf) format('opentype');
}

body{
  background: black;
}

.App {
 font-family:'CompassPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 margin:0px auto;
 max-width: 800px;
 /* margin:0px auto; */
 transition: all 200ms;
 background: black;
 color: white;
}

@media (min-width:1034px){
  .App{
    margin-top: 40px;
  }
}