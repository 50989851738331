body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'CompassPro';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/CompassPro.554c3362.woff) format('woff'),
       url(/static/media/CompassPro.aa5d91d9.otf) format('opentype');
}

body{
  background: black;
}

.App {
 font-family:'CompassPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 margin:0px auto;
 max-width: 800px;
 /* margin:0px auto; */
 -webkit-transition: all 200ms;
 transition: all 200ms;
 background: black;
 color: white;
}

@media (min-width:1034px){
  .App{
    margin-top: 40px;
  }
}
.logo--wrapper{
  text-align: center;
  margin: 30px auto;
}
.logo--svg{
  height: 200px;
  width: 200px;
}

.storeicons--container{
  margin: 0 auto;
  padding: 20px;
  max-width: 380px;
  display: flex;
  justify-content: space-between
}
.playstore--wrapper{
}
.playstore--wrapper, .appstore--wrapper{
  display: inline-block;
  max-width: 160px;
  margin: -40px auto 0;
}

.playstore--svg, .appstore--svg{
  width: 100%;
}

h3{
  margin: 5px auto;
  text-align: center;
  color: rgba(255, 255, 255, 0.733);
  max-width: 255px;
}

h4{
  text-align: center;
  color:#32029b;
  margin: 0 auto;
  text-transform: uppercase;
}
h4:first-of-type{
  margin-top:10px;
color: #00a4ff;
}
