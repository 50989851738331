.logo--wrapper{
  text-align: center;
  margin: 30px auto;
}
.logo--svg{
  height: 200px;
  width: 200px;
}

.storeicons--container{
  margin: 0 auto;
  padding: 20px;
  max-width: 380px;
  display: flex;
  justify-content: space-between
}
.playstore--wrapper{
}
.playstore--wrapper, .appstore--wrapper{
  display: inline-block;
  max-width: 160px;
  margin: -40px auto 0;
}

.playstore--svg, .appstore--svg{
  width: 100%;
}

h3{
  margin: 5px auto;
  text-align: center;
  color: rgba(255, 255, 255, 0.733);
  max-width: 255px;
}

h4{
  text-align: center;
  color:#32029b;
  margin: 0 auto;
  text-transform: uppercase;
}
h4:first-of-type{
  margin-top:10px;
color: #00a4ff;
}